import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import Header from "./Template/Header";
import Home from "./Home";
import Menu from "./Menu";
import SteakoutMenu from "./SteakoutMenu";
import NotFound from "./NotFound";
import Footer from "./Template/Footer";
import Functions from "./Functions";
import SportsBar from "./SportsBar";
import WhatsOn from "./WhatsOn";
import ContactUs from "./ContactUs";
import IconButton from "@material-ui/core/IconButton";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import Bereavement from "./Bereavement";
import Newsletter from "./Newsletter";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    overflowX: "hidden",
  },
}));

const App = () => {
  const classes = useStyles();
  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  return (
    <div className={classes.root}>
      <SnackbarProvider
        maxSnack={3}
        ref={notistackRef}
        action={(key) => (
          <IconButton aria-label="delete" onClick={onClickDismiss(key)}>
            <CancelPresentationIcon fontSize="small" style={{ color: "#FFFFFF" }} />
          </IconButton>
        )}
      >
        <Router>
          <Header />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/menu">
              <Menu />
            </Route>
            <Route path="/steakoutmenu">
              <SteakoutMenu />
            </Route>
            <Route path="/functions">
              <Functions />
            </Route>
            <Route path="/bereavement">
              <Bereavement />
            </Route>
            <Route path="/sportsbar">
              <SportsBar />
            </Route>
            <Route path="/whatson">
              <WhatsOn />
            </Route>
            <Route path="/contact">
              <ContactUs />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
          <Box style={{ padding: "0 10px" }}>
            <Newsletter />
          </Box>
          <Footer />
        </Router>
      </SnackbarProvider>
    </div>
  );
};

export default App;
