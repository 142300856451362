import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

const useStyles = makeStyles((theme) => ({
    footer: {
        padding: theme.spacing(3, 2),
        marginTop: 'auto',
        backgroundColor: theme.palette.footer,
    },
    socials:{
        marginRight: theme.spacing(1)
    },
    socialsOuter:{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginTop: theme.spacing(2)
    },
    middleFooter:{
        textAlign: "center",
    },
    rightFooter:{
        textAlign: "right",
    },
    [theme.breakpoints.down('sm')]: {
        middleFooter:{
            textAlign: "right",
        },
        rightFooter:{
            textAlign: "center",
        }
    },
    [theme.breakpoints.up('md')]: {
        footer:{
            width: "100%",
            borderRadius: "50% / 20px",
            borderBottomRightRadius: "0",
            borderBottomLeftRadius: "0",
        }
    },
    [theme.breakpoints.up('lg')]: {
        footer:{
            width: "100%",
            borderRadius: "50% / 30px",
            borderBottomRightRadius: "0",
            borderBottomLeftRadius: "0",
        }
    },
    [theme.breakpoints.up('xl')]: {
        footer:{
            width: "100%",
            borderRadius: "50% / 50px",
            borderBottomRightRadius: "0",
            borderBottomLeftRadius: "0",
        }
    },
    list: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
        textTransform: 'uppercase'
    }
  }));

const Footer = () =>{
    const classes = useStyles();
    return(
        <footer className={classes.footer}>
            <Container maxWidth="lg">
                <Grid container>
                    <Grid item md={4} sm={6} xs={6}>
                        <Typography gutterBottom variant="h6">OPEN 7 DAYS</Typography>
                        <Typography gutterBottom variant="body1">11:30am - Late</Typography>
                        <Typography gutterBottom variant="h6">OUR SOCIALS</Typography>
                        <a href="https://www.facebook.com/TelegraphHotelGisborne" target="_blank" rel="noreferrer"><FacebookIcon /></a>
                        <a href="https://www.instagram.com/gisbornetele/" target="_blank" rel="noreferrer"><InstagramIcon /></a>
                    </Grid>
                    <Grid item className={classes.middleFooter} xs={6} sm={6} md={4}>
                        <Typography gutterBottom variant="h6">CALL US</Typography>
                        <Typography gutterBottom variant="body1"><a href="tel:0354284011">(03) 5428 4011</a></Typography>
                        <Typography gutterBottom variant="h6">VISIT US</Typography>
                        <Typography gutterBottom variant="body1">19 Hamilton St, Gisborne, Vic, 3437</Typography>
                    </Grid>
                    <Grid item className={classes.rightFooter} xs={12} md={4}>
                        <Typography variant="h6">QUICK LINKS</Typography>
                        <ul className={classes.list}>
                            <li><Link component={RouterLink} color="inherit" to="/">Home</Link></li>
                            <li><Link component={RouterLink} color="inherit" to="/menu">Menu</Link></li>
                            <li><Link component={RouterLink} color="inherit" to="/functions">Functions</Link></li>
                            <li><Link component={RouterLink} color="inherit" to="/whatson">What's On</Link></li>
                            <li><Link component={RouterLink} color="inherit" to="/contact">Contact Us</Link></li>
                        </ul>
                    </Grid>
                </Grid>
                <hr />
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="body2">Copyright &copy; 2021 <a target="_blank" rel="noreferrer" href="https://www.retailbs.com.au">Retail Business Solutions</a></Typography>
                    </Grid>
                    
                </Grid>
            </Container>
      </footer>
    )
}

export default Footer