import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Fade from "@material-ui/core/Fade";
import Slide from "@material-ui/core/Slide";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  switchOrder1: {
    [theme.breakpoints.up("md")]: {
      order: 1,
    },
  },
  switchOrder0: {
    [theme.breakpoints.up("md")]: {
      textAlign: "right",
      order: 0,
    },
  },
  stepContainer: {
    marginBottom: theme.spacing(4),
  },
  media: {
    height: "300px",
  },
  mediaOuter: {
    boxShadow: theme.shadows[24],
  },
}));

const Home = (props) => {
  const classes = useStyles();
  return (
    <Fade timeout={2000} in>
      <Container maxWidth="xl">
        <Box pt={8}>
          <Grid container spacing={6} className={classes.stepContainer}>
            <Grid item xs={12} md={6}>
              <Slide direction="right" timeout={1000} in mountOnEnter unmountOnExit>
                <Card className={classes.mediaOuter}>
                  <CardMedia className={classes.media} image="/images/home-1.jpg" />
                </Card>
              </Slide>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" height="100%">
                <Box my="auto">
                  <Typography variant="h2" color="primary" component="h3">
                    Welcome
                  </Typography>
                  <Typography variant="h4" component="h2" gutterBottom={true}>
                    to the Telegraph Hotel Gisborne
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={6} className={classes.stepContainer}>
            <Grid item xs={12} md={6} className={classes.switchOrder1}>
              <Slide direction="left" timeout={1000} in mountOnEnter unmountOnExit>
                <Card className={classes.mediaOuter}>
                  <CardMedia className={classes.media} image="/images/home-2.jpg" />
                </Card>
              </Slide>
            </Grid>
            <Grid item xs={12} md={6} className={classes.switchOrder0}>
              <Box display="flex" height="100%">
                <Box my="auto">
                  <Typography variant="body1" color="textSecondary" gutterBottom={true}>
                    The Telegraph Hotel is an exceptional venue, the perfect choice for your next event.
                  </Typography>
                  <Typography variant="body1" color="textSecondary" paragraph={true}>
                    Located in picturesque Gisborne, the gateway to the Macedon Ranges, the Telegraph Hotel has the unrivalled packages to suit your needs when hosting a special event.
                  </Typography>
                  <Typography variant="body1" color="textSecondary" paragraph={true}>
                    Birthdays, engagements, wedding receptions, fundraisers, sporting club events, christenings, wakes, and corporate events are all catered for at the Telegraph.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <Slide direction="right" timeout={1000} in mountOnEnter unmountOnExit>
                <Card className={classes.mediaOuter}>
                  <CardMedia className={classes.media} image="/images/home-3.jpg" />
                </Card>
              </Slide>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" height="100%">
                <Box my="auto">
                  <Typography variant="body1" color="textSecondary" paragraph={true}>
                    Our friendly staff ensure your event runs as smoothly as possible.
                  </Typography>
                  <Typography variant="body1" color="textSecondary" paragraph={true}>
                    Contact the hotel on (03) 5428 4011 or email info@telegraphhotel.com.au
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Fade>
  );
};

export default Home;
