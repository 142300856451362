import React from 'react'
import Zoom from '@material-ui/core/Zoom'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const NotFound = props => {

    return(
        <Zoom in>
            <Container maxWidth="md">
                <Box pt={8} pb={10} textAlign="center">
                    <Typography variant="h1">404</Typography>
                    <Typography variant="h4" component="h2" gutterBottom={true}>Page not found</Typography>
                    <Typography variant="subtitle1" color="textSecondary">The requested page couldn't be located.</Typography>
                </Box>
            </Container>
        </Zoom>
    )
}

export default NotFound