import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import Fade from '@material-ui/core/Fade'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import EventSeatRoundedIcon from '@material-ui/icons/EventSeatRounded';
import SupervisorAccountRoundedIcon from '@material-ui/icons/SupervisorAccountRounded';
import PeopleRoundedIcon from '@material-ui/icons/PeopleRounded';
import LocalAtmRoundedIcon from '@material-ui/icons/LocalAtmRounded';
import SettingsInputAntennaRoundedIcon from '@material-ui/icons/SettingsInputAntennaRounded';
import LocalBarRoundedIcon from '@material-ui/icons/LocalBarRounded';
import NightsStayIcon from '@material-ui/icons/NightsStay';

const useStyles = makeStyles((theme) => ({
    menuHeader: {
        textAlign: 'center'
    },
    contentCenter: {
        justifyContent: 'center'
    },
    menuTypes: {
        textTransform: 'uppercase',
        textAlign: 'center'
    },
    priceInfo:{
        justifyContent: 'flex-end',
        marginRight: theme.spacing(1)
    },
    stepContainer: {
        marginBottom: theme.spacing(4)
    },
    media: {
        height: '300px',
    },
    mediaFunctions: {
        maxHeight: '350px',
        maxWidth: '100%',
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[6]
    },
    mediaOuter: {
        boxShadow: theme.shadows[24]
    },
    mediaImages: {
        textAlign: 'center',
    },
    darkCard: {
        backgroundColor: theme.palette.functionCardBackground,
        color: theme.palette.functionCardText,
    },
    cardHeader: {
      backgroundColor: theme.palette.functionCardBackground,
    },
    list: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
        textTransform: 'uppercase'
    }
}))

const Functions = props => {
    const classes = useStyles()

    return (
        <Fade timeout={2000} in>
            <Container maxWidth="xl">
                <Box pt={8} pb={10}>
                    <Grid container spacing={6} alignItems="center" className={classes.stepContainer}>
                        <Grid item xs={12} md={3}>
                            <Box height="100%">
                                <Typography variant="h3" color="primary" component="h3">FUNCTIONS</Typography>
                                <Typography variant="subtitle1" component="h2" gutterBottom={true}>WE OFFER THE CHOICE OF 3 DIFFERENT FUNCTION AREAS TO SUIT YOUR NEEDS.</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Slide direction="left" timeout={1000} in mountOnEnter unmountOnExit>
                                <Card className={classes.mediaOuter}>
                                    <CardMedia className={classes.media} image="/images/function-1.jpg" />
                                </Card>
                            </Slide>
                        </Grid>
                    </Grid>
                    <Grid container spacing={6} alignItems="center" className={classes.stepContainer}>
                        <Grid item xs={12}>
                            <h1 className={classes.menuTypes}>STEAKOUT</h1>
                            <Typography className={classes.menuTypes} variant="subtitle1" component="h3" gutterBottom={true}>Our newly renovated steakout bar is available for your next event.</Typography>
                            <Box my={3}><hr /></Box>
                            <Box my={3}>
                                <Grid container justify="center" alignItems="center" alignContent="center" spacing={1}>
                                    <Grid item xs={12} md={6} className={classes.mediaImages}><img className={classes.mediaFunctions} src="/images/function-2.jpg" alt="Steakout" /></Grid>
                                    <Grid item xs={12} md={6} className={classes.mediaImages}><img className={classes.mediaFunctions} src="/images/function-3.jpg" alt="Steakout" /></Grid>
                                </Grid>
                            </Box>
                            <Grid container justify="center" alignItems="center" alignContent="center" spacing={1}>
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <Card className={classes.darkCard}>
                                        <CardHeader
                                            avatar={
                                                <LocalBarRoundedIcon />
                                            }
                                            title="We have a fully stocked bar and wine list that will tantilise any tastebuds"
                                            titleTypographyProps={{style:{fontWeight: 'bold', textTransform: 'uppercase'}}}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <Card className={classes.darkCard}>
                                        <CardHeader
                                            avatar={
                                                <EventSeatRoundedIcon />
                                            }
                                            title="The options of cocktail style or sit down menu are available"
                                            titleTypographyProps={{style:{fontWeight: 'bold', textTransform: 'uppercase'}}}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <Card className={classes.darkCard}>
                                        <CardHeader
                                            avatar={
                                                <SettingsInputAntennaRoundedIcon />
                                            }
                                            title="Free wifi dock for music available"
                                            titleTypographyProps={{style:{fontWeight: 'bold', textTransform: 'uppercase'}}}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <Card className={classes.darkCard}>
                                        <CardHeader
                                            avatar={
                                                <LocalAtmRoundedIcon />
                                            }
                                            title="Minimum spend: Friday, Saturday and Sunday $4000"
                                            titleTypographyProps={{style:{fontWeight: 'bold', textTransform: 'uppercase'}}}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <Card className={classes.darkCard}>
                                        <CardHeader
                                            avatar={
                                                <LocalAtmRoundedIcon />
                                            }
                                            title=" Minimum spend: Monday – Thursday $2500"
                                            titleTypographyProps={{style:{fontWeight: 'bold', textTransform: 'uppercase'}}}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <Card className={classes.darkCard}>
                                        <CardHeader
                                            avatar={
                                                <PeopleRoundedIcon />
                                            }
                                            title="Cocktail style: 50 – 100 guests."
                                            titleTypographyProps={{style:{fontWeight: 'bold', textTransform: 'uppercase'}}}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <Card className={classes.darkCard}>
                                        <CardHeader
                                            avatar={
                                                <SupervisorAccountRoundedIcon />
                                            }
                                            title="Sit down: 50 guests."
                                            titleTypographyProps={{style:{fontWeight: 'bold', textTransform: 'uppercase'}}}
                                        />
                                    </Card>
                                </Grid>
                            </Grid>     
                        </Grid>
                    </Grid>
                    <Grid container spacing={6} alignItems="center" className={classes.stepContainer}>
                        <Grid item xs={12}>
                            <h1 className={classes.menuTypes}>BISTRO</h1>
                            <Typography className={classes.menuTypes} variant="subtitle1" component="h3" gutterBottom={true}>Our spacious bistro is the perfect setting for larger numbers either seating or cocktail style.</Typography>
                            <Box my={3}><hr /></Box>
                            <Box my={3}>
                                <Grid container justify="center" alignItems="center" alignContent="center" spacing={1}>
                                    <Grid item xs={12} md={6} className={classes.mediaImages}><img className={classes.mediaFunctions} src="/images/function-4.jpg" alt="Steakout" /></Grid>
                                    <Grid item xs={12} md={6} className={classes.mediaImages}><img className={classes.mediaFunctions} src="/images/function-5.jpg" alt="Steakout" /></Grid>
                                </Grid>
                            </Box>
                            <Grid container justify="center" alignItems="center" alignContent="center" spacing={1}>
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <Card className={classes.darkCard}>
                                        <CardHeader
                                            avatar={
                                                <LocalBarRoundedIcon />
                                            }
                                            title="We have a fully stocked bar and wine list that will tantilise any tastebuds"
                                            titleTypographyProps={{style:{fontWeight: 'bold', textTransform: 'uppercase'}}}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <Card className={classes.darkCard}>
                                        <CardHeader
                                            avatar={
                                                <EventSeatRoundedIcon />
                                            }
                                            title="The options of cocktail style or sit down menu are available"
                                            titleTypographyProps={{style:{fontWeight: 'bold', textTransform: 'uppercase'}}}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <Card className={classes.darkCard}>
                                        <CardHeader
                                            avatar={
                                                <LocalAtmRoundedIcon />
                                            }
                                            title="Minimum spend: Friday, Saturday and Sunday $6000"
                                            titleTypographyProps={{style:{fontWeight: 'bold', textTransform: 'uppercase'}}}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <Card className={classes.darkCard}>
                                        <CardHeader
                                            avatar={
                                                <LocalAtmRoundedIcon />
                                            }
                                            title="Minimum spend: Monday – Thursday $3500"
                                            titleTypographyProps={{style:{fontWeight: 'bold', textTransform: 'uppercase'}}}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <Card className={classes.darkCard}>
                                        <CardHeader
                                            avatar={
                                                <PeopleRoundedIcon />
                                            }
                                            title="Cocktail style: 50 – 200 guests"
                                            titleTypographyProps={{style:{fontWeight: 'bold', textTransform: 'uppercase'}}}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <Card className={classes.darkCard}>
                                        <CardHeader
                                            avatar={
                                                <SupervisorAccountRoundedIcon />
                                            }
                                            title="Sit down: 75 guests"
                                            titleTypographyProps={{style:{fontWeight: 'bold', textTransform: 'uppercase'}}}
                                        />
                                    </Card>
                                </Grid>
                            </Grid>     
                        </Grid>
                    </Grid>
                    <Grid container spacing={6} alignItems="center" className={classes.stepContainer}>
                        <Grid item xs={12}>
                            <h1 className={classes.menuTypes}>BEER GARDEN</h1>
                            <Typography className={classes.menuTypes} variant="subtitle1" component="h3" gutterBottom={true}>The Outdoor area at the Telegraph Hotel is an incomparable environment to hold your next event.</Typography>
                            <Box my={3}><hr /></Box>
                            {/* <Box my={3}>
                                <Grid container justify="center" alignItems="center" alignContent="center" spacing={1}>
                                    <Grid item xs={12} md={6} className={classes.mediaImages}><img className={classes.mediaFunctions} src="/images/function-2.jpg" alt="Steakout" /></Grid>
                                    <Grid item xs={12} md={6} className={classes.mediaImages}><img className={classes.mediaFunctions} src="/images/function-3.jpg" alt="Steakout" /></Grid>
                                </Grid>
                            </Box> */}
                            <Grid container justify="center" alignItems="center" alignContent="center" spacing={1}>
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <Card className={classes.darkCard}>
                                        <CardHeader
                                            avatar={
                                                <LocalBarRoundedIcon />
                                            }
                                            title="Exclusive use of our Beer Garden, your own bar outside &amp; a large screen T.V."
                                            titleTypographyProps={{style:{fontWeight: 'bold', textTransform: 'uppercase'}}}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <Card className={classes.darkCard}>
                                        <CardHeader
                                            avatar={
                                                <NightsStayIcon />
                                            }
                                            title="You can enjoy the night sky with the stars shining or summer sun to celebrate under"
                                            titleTypographyProps={{style:{fontWeight: 'bold', textTransform: 'uppercase'}}}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <Card className={classes.darkCard}>
                                        <CardHeader
                                            avatar={
                                                <LocalAtmRoundedIcon />
                                            }
                                            title="Minimum spend: Friday, Saturday and Sunday $3500"
                                            titleTypographyProps={{style:{fontWeight: 'bold', textTransform: 'uppercase'}}}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <Card className={classes.darkCard}>
                                        <CardHeader
                                            avatar={
                                                <LocalAtmRoundedIcon />
                                            }
                                            title=" Minimum spend: Monday – Thursday $2000"
                                            titleTypographyProps={{style:{fontWeight: 'bold', textTransform: 'uppercase'}}}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <Card className={classes.darkCard}>
                                        <CardHeader
                                            avatar={
                                                <PeopleRoundedIcon />
                                            }
                                            title="Cocktail style: 50 – 150 guests"
                                            titleTypographyProps={{style:{fontWeight: 'bold', textTransform: 'uppercase'}}}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={12} lg={8} xl={8}>
                                    <Card className={classes.darkCard}>
                                        <CardHeader
                                            avatar={
                                                <SupervisorAccountRoundedIcon />
                                            }
                                            title="Winter functions are happily accommodated, however, a marquee would need to be erected to provide for varying weather conditions"
                                            titleTypographyProps={{style:{fontWeight: 'bold', textTransform: 'uppercase'}}}
                                            subheader="Additional costs may apply."
                                        />
                                    </Card>
                                </Grid>
                            </Grid>     
                        </Grid>
                    </Grid>
                    <Grid container spacing={5} alignItems="flex-end" className={classes.stepContainer}>
                        <Grid item xs={12}>
                            <h1 className={classes.menuTypes}>FINGER FOOD PRICING</h1>
                            <Typography className={classes.menuTypes} variant="subtitle1" component="h3" gutterBottom={true}>Choose from a selection of catering to suit your needs</Typography>
                            <Box my={3}><hr /></Box>
                            <Grid container spacing={5}>
                                <Grid item xs={12} sm={12} md={4} xl={4}>
                                    <Card>
                                        <CardHeader
                                            title="$17 PER HEAD"
                                            titleTypographyProps={{ align: 'center' }}
                                            subheader="5 items from Tier 1"
                                            subheaderTypographyProps={{ align: 'center' }}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} xl={4}>
                                    <Card>
                                        <CardHeader
                                            title="$25 PER HEAD"
                                            titleTypographyProps={{ align: 'center' }}
                                            subheader="5 items from Tier 1 or Tier 2"
                                            subheaderTypographyProps={{ align: 'center' }}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} xl={4}>
                                    <Card>
                                        <CardHeader
                                            title="$35 PER HEAD"
                                            titleTypographyProps={{ align: 'center' }}
                                            subheader="5 items from Tier 1, Tier 2 or Tier 3"
                                            subheaderTypographyProps={{ align: 'center' }}
                                        />
                                    </Card>
                                </Grid>
                            </Grid>
                            <Typography className={classes.menuTypes} variant="subtitle2" component="p" gutterBottom={true}>Add $3 per head for coffee and tea station.</Typography>
                            <Box my={3}><hr /></Box>
                            <Grid container spacing={5}>
                                <Grid item xs={12} sm={12} md={4} xl={4}>
                                    <Card>
                                        <CardHeader
                                            title="TIER 1"
                                            titleTypographyProps={{ align: 'center' }}
                                            className={classes.cardHeader}
                                        />
                                        <CardContent>
                                            <ul className={classes.list}>
                                                <Typography component="li" variant="subtitle1" align="center">Cocktail vegetable spring rolls</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Mini vegetable samosa</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Mini beef dim sims</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Salt &amp; vinegar potato cakes</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Party pies</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Sausage rolls</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Spinack &amp; ricotta pastizzi</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Fish bites</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Bruschetta bites</Typography>
                                            </ul>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} xl={4}>
                                    <Card>
                                        <CardHeader
                                            title="TIER 2"
                                            titleTypographyProps={{ align: 'center' }}
                                            className={classes.cardHeader}
                                        />
                                        <CardContent>
                                            <ul className={classes.list}>
                                                <Typography component="li" variant="subtitle1" align="center">Cocktail franks</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Kiev balls</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Mini pizzas</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Peri peri chicken ribs</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Sweet soy meatballs</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Pork belly buns</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Salt &amp; pepper calamari</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Dip board</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Steamed chicken dim sims</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Crumbed cauliflower flowerettes</Typography>
                                            </ul>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} xl={4}>
                                    <Card>
                                        <CardHeader
                                            title="TIER 3"
                                            titleTypographyProps={{ align: 'center' }}
                                            className={classes.cardHeader}
                                        />
                                        <CardContent>
                                            <ul className={classes.list}>
                                                <Typography component="li" variant="subtitle1" align="center">Mini quiches</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Smoked salmon bruschetta</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Nori rolls</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Prawn twisters</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Char grilled vegetable skewers</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">San choy bow</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Crimbed mozzarella sticks</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Sshimi canape</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Seasoned chicken sliders</Typography>
                                            </ul>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={5} alignItems="flex-end" className={classes.stepContainer}>
                        <Grid item xs={12}>
                            <h1 className={classes.menuTypes}>Bereavement &amp; Wake Package</h1>
                            <Typography className={classes.menuTypes} variant="subtitle1" component="h3" gutterBottom={true}>Choose from a selection of catering to suit your needs</Typography>
                            <Box my={3}><hr /></Box>
                            <Grid container spacing={5}>
                                <Grid item xs={12} sm={12} md={4} xl={4}>
                                    <Card>
                                        <CardHeader
                                            title="$20 PER HEAD"
                                            titleTypographyProps={{ align: 'center' }}
                                            subheader="5 items from Tier 1"
                                            subheaderTypographyProps={{ align: 'center' }}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} xl={4}>
                                    <Card>
                                        <CardHeader
                                            title="$29 PER HEAD"
                                            titleTypographyProps={{ align: 'center' }}
                                            subheader="5 items from Tier 1 or Tier 2"
                                            subheaderTypographyProps={{ align: 'center' }}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} xl={4}>
                                    <Card>
                                        <CardHeader
                                            title="$37.50 PER HEAD"
                                            titleTypographyProps={{ align: 'center' }}
                                            subheader="5 items from Tier 1, Tier 2 or Tier 3"
                                            subheaderTypographyProps={{ align: 'center' }}
                                        />
                                    </Card>
                                </Grid>
                            </Grid>
                            <Box my={3}><hr /></Box>
                            <Grid container spacing={5}>
                                <Grid item xs={12} sm={12} md={4} xl={4}>
                                    <Card>
                                        <CardHeader
                                            title="TIER 1"
                                            titleTypographyProps={{ align: 'center' }}
                                            className={classes.cardHeader}
                                        />
                                        <CardContent>
                                            <ul className={classes.list}>
                                                <Typography component="li" variant="subtitle1" align="center">Cocktail vegetable spring rolls</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Mini vegetable samosa</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Mini beef dim sims</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Salt &amp; vinegar potato ckaes</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Party pies</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Sausage rolls</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Spinach &amp; ricotta pastizzi</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Fish bites</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Bruschetta bites</Typography>
                                                <Typography component="li" variant="subtitle1" align="center"><strong>Unlimited Tea &amp; Coffee</strong></Typography>
                                            </ul>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} xl={4}>
                                    <Card>
                                        <CardHeader
                                            title="TIER 2"
                                            titleTypographyProps={{ align: 'center' }}
                                            className={classes.cardHeader}
                                        />
                                        <CardContent>
                                            <ul className={classes.list}>
                                                <Typography component="li" variant="subtitle1" align="center">Cocktail franks</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Kiev balls</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Mini pizzas</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Peri peri chicken ribs</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Sweet soy meatballs</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Pork belly buns</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Salt &amp; pepper calamari</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Dip board</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Steamed chicken dim sims</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Crumbed cauliflower flowerettes</Typography>
                                                <Typography component="li" variant="subtitle1" align="center"><strong>Unlimited Tea &amp; Coffee</strong></Typography>
                                            </ul>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} xl={4}>
                                    <Card>
                                        <CardHeader
                                            title="TIER 3"
                                            titleTypographyProps={{ align: 'center' }}
                                            className={classes.cardHeader}
                                        />
                                        <CardContent>
                                            <ul className={classes.list}>
                                                <Typography component="li" variant="subtitle1" align="center">Mini quiches</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Smoked salmon bruschetta</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Nori rolls</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Prawn twisters</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Char grilled vegetable skewers</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">San choy bow</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Crumbed mozzarella sticks</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Sushimi canape</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Seasoned chicken sliders</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Scones, Jam &amp; Cream</Typography>
                                                <Typography component="li" variant="subtitle1" align="center"><strong>Unlimited Tea &amp; Coffee</strong></Typography>
                                            </ul>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Fade>
    )
}

export default Functions