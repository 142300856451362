import React from "react";
import { Button, Typography, makeStyles, Container } from "@material-ui/core";

// Define styles with a background image
const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: "url(images/newsletter.png)", // Replace with your background image URL
    backgroundSize: "cover",
    backgroundPosition: "center",
    textAlign: "center",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
  content: {
    padding: theme.spacing(10),
    borderRadius: theme.shape.borderRadius,
    maxWidth: 900,
  },
  input: {
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(2),
    width: "100%",
    fontWeight: "bold",
  },
}));

const Newsletter = () => {
  const classes = useStyles();

  const handleSignup = () => {
    window.open("https://www.sevenrooms.com/explore/gisbornetelegraphhotel/subscription", "_blank");
  };

  return (
    <Container className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h4" gutterBottom>
          Sign up for The Telegraph Hotel Newsletter
        </Typography>
        <Typography variant="body1" gutterBottom>
          Stay updated with the latest news and events at The Telegraph Hotel!
        </Typography>
        <Button variant="contained" color="secondary" className={classes.button} onClick={handleSignup}>
          Sign Up Now
        </Button>
      </div>
    </Container>
  );
};

export default Newsletter;
