import React, { useState } from 'react'
import axios from 'axios'
import { useSnackbar } from 'notistack';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Fade from '@material-ui/core/Fade'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import CardMedia from '@material-ui/core/CardMedia';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';

const useStyles = makeStyles((theme) => ({
    menuHeader: {
        textAlign: 'center'
    },
    contentCenter: {
        justifyContent: 'center'
    },
    menuTypes: {
        textTransform: 'uppercase',
        textAlign: 'center'
    },
    stepContainer: {
        marginBottom: theme.spacing(4)
    },
    stepContainer1: {
        marginBottom: theme.spacing(4),
        textAlign: 'center'
    },
    media: {
        height: '300px',
    },
    mediaFunctions: {
        maxHeight: '350px',
        maxWidth: '100%',
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[6]
    },
    mediaOuter: {
        boxShadow: theme.shadows[24]
    },
    mediaImages: {
        textAlign: 'center',
    },
    darkCard: {
        backgroundColor: theme.palette.functionCardBackground,
        color: theme.palette.functionCardText,
    },
    cardHeader: {
      backgroundColor: theme.palette.functionCardBackground,
    },
    list: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
        textTransform: 'uppercase'
    }
}))

const ContactUs = props => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles()
    const [name, setname] = useState("")
    const [email, setemail] = useState("")
    const [message, setmessage] = useState("")
    const [issending, setissending] = useState(false)
    const [messagesent, setmessagesent] = useState(false)

    function checkHuman() {
        setissending(true)
        if(!name){
            enqueueSnackbar('Please enter a valid name!', { 
                variant: 'error',
            });
            setissending(false)
            return
        }
        if(!isEmail(email)){
            enqueueSnackbar('Please enter a valid email address!', { 
                variant: 'error',
            });
            setissending(false)
            return
        }
        if(!message){
            enqueueSnackbar('Please enter a valid message!', { 
                variant: 'error',
            });
            setissending(false)
            return
        }
        submitForm();
    }

    function submitForm() {
        axios.post("https://tele.retailbs.com.au/api/send_message.php", {name:name, email:email, message:message})
        .then(res => { 
            if(res.data.error){
                enqueueSnackbar(res.data.message, { 
                    variant: 'error',
                });
            } else {
                enqueueSnackbar(res.data.message, { 
                    variant: 'success',
                });
                resetForm()
                setmessagesent(true)
            }
            setissending(false)
        })
        .catch(err=>{
            enqueueSnackbar('An error occured sending your message, please try again!', { 
                variant: 'error',
            });
            setissending(false)
        })
        
    }
    function isEmail(val) {
        let regEmail = /[^@\s]+@[^@\s]+\.[^@\s]+/;
        if(!regEmail.test(val)){
          return false;
        }
        return true;
    }

    function resetForm() {
        setname("")
        setemail("")
        setmessage("")
    }

    let buttontext = "Send Message"
    if(issending){
        buttontext = "Please Wait..."
    }

    let form = <form autoComplete="off">
                    <h2>SEND US AN ENQUIRY</h2>
                    <FormControl margin="normal" fullWidth>
                        <InputLabel htmlFor="name">Name</InputLabel>
                        <Input autoComplete="off" onChange={(e)=>setname(e.target.value)} id="name" type="text" fullWidth />
                    </FormControl>

                    <FormControl margin="normal" fullWidth>
                        <InputLabel htmlFor="email">Email</InputLabel>
                        <Input autoComplete="off" onChange={(e)=>setemail(e.target.value)} id="email" type="email" />
                    </FormControl>

                    <FormControl margin="normal" fullWidth>
                        <InputLabel htmlFor="email">Message</InputLabel>
                        <Input autoComplete="off" onChange={(e)=>setmessage(e.target.value)} id="message" multiline rows={10} />
                    </FormControl>

                    <Button variant="contained" disabled={issending} onClick={()=>checkHuman()} color="secondary" size="medium">
                        {buttontext}
                    </Button>
                </form>

    if(messagesent){
        form = <Typography variant="h6" gutterBottom className={classes.details}>
                Your message has been sent successfully<br />We'll be in contact regarding your enquiry as soon as we can.
            </Typography>
    }

    return (
        <Fade timeout={2000} in>
            <Container maxWidth="xl">
                <Box pt={8} pb={10}>
                    <Grid container spacing={6} alignItems="center" className={classes.stepContainer}>
                        <Grid item xs={12} md={3}>
                            <Box height="100%">
                                <Typography variant="h3" color="primary" component="h3">CONTACT US</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Slide direction="left" timeout={1000} in mountOnEnter unmountOnExit>
                                <Card className={classes.mediaOuter}>
                                    <CardMedia className={classes.media} image="/images/contact-us-1.jpg" />
                                </Card>
                            </Slide>
                        </Grid>
                    </Grid>
                    <Grid container spacing={6} alignItems="flex-start" justify="center" className={classes.stepContainer1}>
                        <Grid item xs={6} md={4}>
                            <LocationOnRoundedIcon color="secondary" style={{ fontSize: 90 }} />
                            <Typography variant="h5" color="primary" component="h5" gutterBottom={true}>ADDRESS</Typography>
                            <Typography variant="h6" color="secondary" component="h6" gutterBottom={true}>19 Hamilton St. Gisborne, Vic, 3437</Typography>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <LocationOnRoundedIcon color="secondary" style={{ fontSize: 90 }} />
                            <Typography variant="h5" color="primary" component="h5" gutterBottom={true}>PHONE</Typography>
                            <Typography variant="h6" color="secondary" component="h6" gutterBottom={true}><Link color="secondary" href="tel:0354284011">(03) 5428 4011</Link></Typography>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <LocationOnRoundedIcon color="secondary" style={{ fontSize: 90 }} />
                            <Typography variant="h5" color="primary" component="h5" gutterBottom={true}>EMAIL</Typography>
                            <Typography variant="h6" color="secondary" component="h6" gutterBottom={true}><Link color="secondary" href="mailto:info@telegraphhotel.com.au">info@telegraphhotel.com.au</Link></Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={6} alignItems="center" justify="center" className={classes.stepContainer}>
                        <Grid item xs={12} md={8}>
                            <Card>
                                <CardContent>
                                    {form}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Fade>
    )
}

export default ContactUs