import React from 'react';
import ReactDOM from 'react-dom';
import '@fontsource/roboto';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import './index.css';
import App from './App';
import { grey, orange } from '@material-ui/core/colors';
import CssBaseline from '@material-ui/core/CssBaseline';

const theme = createMuiTheme({
  palette: {
    primary: grey,
    secondary: orange,
    background: {
      default: '#fff4eb',
    },
    header: '#ffffff',
    footer: '#eadbcb',
    menuHover: '#eadbcb',
    menuButton: '#422900',
    functionCardBackground: '#eadbcb',
    functionCardText: '#000000'
  },
  shape: {
    borderRadius: 10,
  }
});
ReactDOM.render(
  <>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </>,
  document.getElementById('th-root')
);
