import React, { useState, useEffect } from 'react'
import axios from 'axios'
import makeStyles from '@material-ui/core/styles/makeStyles';
import Fade from '@material-ui/core/Fade'
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Alert from '@material-ui/lab/Alert'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import RestaurantIcon from '@material-ui/icons/Restaurant';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles((theme) => ({
    menuHeader: {
        textAlign: 'center'
    },
    contentCenter: {
        justifyContent: 'center'
    },
    menuTypes: {
        textTransform: 'uppercase',
        textAlign: 'center'
    },
    priceInfo:{
        justifyContent: 'flex-end',
        marginRight: theme.spacing(1)
    },
    stepContainer: {
        marginBottom: theme.spacing(4)
    },
    media: {
        height: '300px',
    },
    mediaOuter: {
        boxShadow: theme.shadows[24]
    },
}))

const Menu = props => {
    const classes = useStyles()
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    async function fetchData() {
        let response = await axios.get("https://tele.retailbs.com.au/api/get_menu.php").then((res)=>{
            if(!res.data.error){
                return res.data.data
            }
            return []
        }).catch(err => {
            return []
        })
        setData(response);
        setLoading(false)
      }

    useEffect(() => {
        fetchData();
    }, [])

    let menu = <Grid item className={classes.menuHeader} xs={12}>
                    <Fade in>
                        <CircularProgress />
                    </Fade>
                </Grid>

    if(!loading){
        if(data.length > 0){
            menu = data.map((val, idx)=>{
                let menuItems
                if(val.items.length > 0){
                    menuItems = val.items.map((mval, midx)=>{
                        if(mval.meal_name){
                            return  <Grid key={midx} item xs={12} md={6} lg={4} xl={4}>
                                        <Card className={classes.root}>
                                            <CardHeader
                                                avatar={
                                                    <RestaurantIcon />
                                                }
                                                title={mval.meal_name}
                                                titleTypographyProps={{style:{fontWeight: 'bold', textTransform: 'uppercase'}}}
                                                subheader={mval.meal_desc}
                                            />
                                            <CardActions className={classes.priceInfo}>
                                                <Typography variant="h6" component="p">{mval.price}</Typography>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                        }
                        return false
                    })
                }
                return  <Fade key={idx} in>
                            <Grid item xs={12}>
                                <h2 className={classes.menuTypes}>{val.type_name}</h2>
                                {(val.menu_desc) && <Typography className={classes.menuTypes} variant="subtitle1" component="h5" gutterBottom={true}>{val.menu_desc}</Typography>}
                                <hr />
                                <Grid container justify="center" alignItems="flex-start" alignContent="flex-start" spacing={1}>
                                    {menuItems}  
                                </Grid>                              
                            </Grid>
                        </Fade>
            })
        } else {
            menu = <Grid item xs={12}>
            <Fade in>
                <Alert className={classes.contentCenter} variant="filled" severity="error">An error occured loading the Menu, please try again.</Alert>
            </Fade>
        </Grid>
        }
    }
    return (
        <Fade timeout={2000} in>
            <Container maxWidth="xl">
                <Box pt={8} pb={10}>
                    <Grid container spacing={6} alignItems="center" className={classes.stepContainer}>
                        <Grid item xs={12} md={3}>
                            <Box  height="100%">
                                <Typography variant="h3" color="primary" component="h3">MENU</Typography>
                                <Typography variant="subtitle1" component="h2" gutterBottom={true}>OUR MENU OFFERS PUB CLASSICS, ALONG WITH SEASONAL DISHES TO MAKE THE BEST USE OF LOCAL ITEMS.</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Slide direction="left" timeout={1000} in mountOnEnter unmountOnExit>
                                <Card className={classes.mediaOuter}>
                                    <CardMedia className={classes.media} image="/images/menu-1.jpg" />
                                </Card>
                            </Slide>
                        </Grid>
                        
                    </Grid>
                    <Grid container justify="center" alignItems="center" spacing={6}>
                        {menu}
                    </Grid>
                </Box>
            </Container>
        </Fade>
    )
}

export default Menu