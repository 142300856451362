import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Fade from "@material-ui/core/Fade";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import CardMedia from "@material-ui/core/CardMedia";

const useStyles = makeStyles((theme) => ({
  menuHeader: {
    textAlign: "center",
  },
  contentCenter: {
    justifyContent: "center",
  },
  menuTypes: {
    textTransform: "uppercase",
    textAlign: "center",
  },
  priceInfo: {
    justifyContent: "flex-end",
    marginRight: theme.spacing(1),
  },
  stepContainer: {
    marginBottom: theme.spacing(4),
  },
  stepContainer1: {
    marginBottom: theme.spacing(4),
    textAlign: "center",
  },
  media: {
    height: "300px",
  },
  mediaFunctions: {
    maxHeight: "350px",
    maxWidth: "100%",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[6],
  },
  mediaOuter: {
    boxShadow: theme.shadows[24],
  },
  mediaImages: {
    textAlign: "center",
  },
  darkCard: {
    backgroundColor: theme.palette.functionCardBackground,
    color: theme.palette.functionCardText,
  },
  cardHeader: {
    backgroundColor: theme.palette.functionCardBackground,
  },
  list: {
    margin: 0,
    padding: 0,
    listStyle: "none",
    textTransform: "uppercase",
  },
}));

const SportsBar = (props) => {
  const classes = useStyles();

  return (
    <Fade timeout={2000} in>
      <Container maxWidth="xl">
        <Box pt={8} pb={10}>
          <Grid container spacing={6} alignItems="center" className={classes.stepContainer}>
            <Grid item xs={12} md={3}>
              <Box height="100%">
                <Typography variant="h3" color="primary" component="h3">
                  SPORTS BAR
                </Typography>
                <Typography variant="subtitle1" component="h2" gutterBottom={true}>
                  COME JOIN US FOR A DRINK, WATCH LIVE SPORT AND GRAB A BITE TO EAT.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={9}>
              <Slide direction="left" timeout={1000} in mountOnEnter unmountOnExit>
                <Card className={classes.mediaOuter}>
                  <CardMedia className={classes.media} image="/images/sports-bar-1.jpg" />
                </Card>
              </Slide>
            </Grid>
          </Grid>
          <Grid container spacing={6} alignItems="center" justify="center" className={classes.stepContainer1}>
            <Grid item xs={12} lg={10}>
              <Box height="100%" my={2}>
                <Typography variant="h4" component="h4" gutterBottom={true}>
                  Watch all the action of live AFL on our big screens and join our local punters for an afternoon or evening of live racing and sports.
                </Typography>
                <Typography variant="body1" component="h3" gutterBottom={true}>
                  We pride ourselves on bringing you LIVE coverage of sport and racing events such as the AFL &amp; NRL Grandfinals, State of Origin, Melbourne Cup, Cox Plate, Oaks Day &amp; more.
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={6} alignItems="center" justify="center" className={classes.stepContainer}>
            <Grid item xs={12}>
              <Box height="100%">
                <Typography variant="h5" component="div">
                  PROUD SPONSORS OF THE FOLLOWING LOCAL CLUBS
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={9}>
              <Slide direction="left" timeout={1000} in mountOnEnter unmountOnExit>
                <Card className={classes.mediaOuter}>
                  <img alt="sporting clubs" style={{ maxWidth: "100%" }} src="/images/sporting-clubs1-new.jpg" />
                </Card>
              </Slide>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Fade>
  );
};

export default SportsBar;
