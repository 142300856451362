import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import Fade from '@material-ui/core/Fade'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles((theme) => ({
    menuHeader: {
        textAlign: 'center'
    },
    contentCenter: {
        justifyContent: 'center'
    },
    menuTypes: {
        textTransform: 'uppercase',
        textAlign: 'center'
    },
    priceInfo:{
        justifyContent: 'flex-end',
        marginRight: theme.spacing(1)
    },
    stepContainer: {
        marginBottom: theme.spacing(4)
    },
    media: {
        height: '300px',
    },
    mediaFunctions: {
        maxHeight: '350px',
        maxWidth: '100%',
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[6]
    },
    mediaOuter: {
        boxShadow: theme.shadows[24]
    },
    mediaImages: {
        textAlign: 'center',
    },
    darkCard: {
        backgroundColor: theme.palette.functionCardBackground,
        color: theme.palette.functionCardText,
    },
    cardHeader: {
      backgroundColor: theme.palette.functionCardBackground,
    },
    list: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
        textTransform: 'uppercase'
    }
}))

const Bereavement = props => {
    const classes = useStyles()

    return (
        <Fade timeout={2000} in>
            <Container maxWidth="xl">
                <Box pt={8} pb={10}>
                    <Grid container spacing={5} alignItems="flex-end" className={classes.stepContainer}>
                        <Grid item xs={12}>
                            <h1 className={classes.menuTypes}>Bereavement &amp; Wake Package</h1>
                            <Typography className={classes.menuTypes} variant="subtitle1" component="h3" gutterBottom={true}>Choose from a selection of catering to suit your needs</Typography>
                            <Box my={3}><hr /></Box>
                            <Grid container spacing={5}>
                                <Grid item xs={12} sm={12} md={4} xl={4}>
                                    <Card>
                                        <CardHeader
                                            title="$20 PER HEAD"
                                            titleTypographyProps={{ align: 'center' }}
                                            subheader="5 items from Tier 1"
                                            subheaderTypographyProps={{ align: 'center' }}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} xl={4}>
                                    <Card>
                                        <CardHeader
                                            title="$29 PER HEAD"
                                            titleTypographyProps={{ align: 'center' }}
                                            subheader="5 items from Tier 1 or Tier 2"
                                            subheaderTypographyProps={{ align: 'center' }}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} xl={4}>
                                    <Card>
                                        <CardHeader
                                            title="$37.50 PER HEAD"
                                            titleTypographyProps={{ align: 'center' }}
                                            subheader="5 items from Tier 1, Tier 2 or Tier 3"
                                            subheaderTypographyProps={{ align: 'center' }}
                                        />
                                    </Card>
                                </Grid>
                            </Grid>
                            <Box my={3}><hr /></Box>
                            <Grid container spacing={5}>
                                <Grid item xs={12} sm={12} md={4} xl={4}>
                                    <Card>
                                        <CardHeader
                                            title="TIER 1"
                                            titleTypographyProps={{ align: 'center' }}
                                            className={classes.cardHeader}
                                        />
                                        <CardContent>
                                            <ul className={classes.list}>
                                                <Typography component="li" variant="subtitle1" align="center">Cocktail vegetable spring rolls</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Mini vegetable samosa</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Mini beef dim sims</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Salt &amp; vinegar potato ckaes</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Party pies</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Sausage rolls</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Spinach &amp; ricotta pastizzi</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Fish bites</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Bruschetta bites</Typography>
                                                <Typography component="li" variant="subtitle1" align="center"><strong>Unlimited Tea &amp; Coffee</strong></Typography>
                                            </ul>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} xl={4}>
                                    <Card>
                                        <CardHeader
                                            title="TIER 2"
                                            titleTypographyProps={{ align: 'center' }}
                                            className={classes.cardHeader}
                                        />
                                        <CardContent>
                                            <ul className={classes.list}>
                                                <Typography component="li" variant="subtitle1" align="center">Cocktail franks</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Kiev balls</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Mini pizzas</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Peri peri chicken ribs</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Sweet soy meatballs</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Pork belly buns</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Salt &amp; pepper calamari</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Dip board</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Steamed chicken dim sims</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Crumbed cauliflower flowerettes</Typography>
                                                <Typography component="li" variant="subtitle1" align="center"><strong>Unlimited Tea &amp; Coffee</strong></Typography>
                                            </ul>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} xl={4}>
                                    <Card>
                                        <CardHeader
                                            title="TIER 3"
                                            titleTypographyProps={{ align: 'center' }}
                                            className={classes.cardHeader}
                                        />
                                        <CardContent>
                                            <ul className={classes.list}>
                                                <Typography component="li" variant="subtitle1" align="center">Mini quiches</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Smoked salmon bruschetta</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Nori rolls</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Prawn twisters</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Char grilled vegetable skewers</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">San choy bow</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Crumbed mozzarella sticks</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Sushimi canape</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Seasoned chicken sliders</Typography>
                                                <Typography component="li" variant="subtitle1" align="center">Scones, Jam &amp; Cream</Typography>
                                                <Typography component="li" variant="subtitle1" align="center"><strong>Unlimited Tea &amp; Coffee</strong></Typography>
                                            </ul>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Fade>
    )
}

export default Bereavement