import React, { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import StorageRoundedIcon from "@material-ui/icons/StorageRounded";
import { Alert } from "@material-ui/lab";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.header,
  },
  drawer: {
    width: "250px",
  },
  menuButton: {
    height: "60px",
    width: "60px",
    color: theme.palette.menuButton,
    margin: "auto",
  },
  logo: {
    maxWidth: "100%",
    height: "auto",
    maxHeight: "150px",
  },
  links: {
    fontWeight: "bold",
    fontSize: "1.2rem",
    paddingLeft: "6px",
    paddingRight: "6px",
    textTransform: "uppercase",
    display: "inline-block",
    position: "relative",
    cursor: "pointer",
    zIndex: 2,
    whiteSpace: "nowrap",
    textDecoration: "none",
    letterSpacing: ".4rem",
    "&::before": {
      content: '""',
      position: "absolute",
      width: "0",
      bottom: "-7px",
      left: "-10px",
      zIndex: -1,
      background: theme.palette.menuHover,
      height: "35px",
      borderRadius: "10px",
      visibility: "hidden",
      transition: "all 0.3s ease-in-out",
    },
    "&:hover:before": {
      visibility: "visible",
      width: "calc(100% + 16px)",
    },
    "&:hover": {
      textDecoration: "none",
    },
  },
  bookText: {
    marginTop: "12px",
  },
  [theme.breakpoints.down("lg")]: {
    links: {
      fontSize: ".9rem",
      letterSpacing: ".1rem",
      paddingLeft: "2px",
      paddingRight: "2px",
    },
  },
  [theme.breakpoints.down("md")]: {
    links: {
      fontSize: ".9rem",
      letterSpacing: ".1rem",
    },
    book: {
      marginTop: "10px",
      display: "block",
    },
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const [isOpen, setisOpen] = useState(false);

  return (
    <header className={classes.header}>
      <Alert
        style={{ fontWeight: "bold", fontSize: "1.5rem", lineHeight: "1.5rem", verticalAlign: "center", backgroundColor: "brown", borderRadius: 0, color: "white", textAlign: "center", justifyContent: "center", alignContent: "center" }}
        icon={false}
      >
        <span className={classes.bookText}>We now take online bookings!</span>
        <Button
          className={classes.book}
          style={{ marginLeft: "10px", backgroundColor: "white", fontWeight: "bold", fontSize: "1rem" }}
          variant="contained"
          href="https://www.sevenrooms.com/explore/gisbornetelegraphhotel/reservations/create/search"
          target="_blank"
        >
          Book Now
        </Button>
      </Alert>
      <Box display="flex" p={1}>
        <Box flexGrow={1}>
          <img src="/images/logo.png" alt="logo" className={classes.logo} />
        </Box>
        <Hidden mdDown>
          <Box display="flex" p={1}>
            <Box alignSelf="center" mx={2}>
              <Link component={RouterLink} color="inherit" className={classes.links} to="/">
                Home
              </Link>
            </Box>
            <Box alignSelf="center" mx={2}>
              <Link component={RouterLink} color="inherit" className={classes.links} to="/menu">
                Menu
              </Link>
            </Box>
            <Box alignSelf="center" mx={2}>
              <Link component={RouterLink} color="inherit" className={classes.links} to="/steakoutmenu">
                Steakout Menu
              </Link>
            </Box>
            <Box alignSelf="center" mx={2}>
              <Link component={RouterLink} color="inherit" className={classes.links} to="/functions">
                Functions
              </Link>
            </Box>
            <Box alignSelf="center" mx={2}>
              <Link component={RouterLink} color="inherit" className={classes.links} to="/bereavement">
                BEREAVEMENT/WAKE
              </Link>
            </Box>
            <Box alignSelf="center" mx={2}>
              <Link component={RouterLink} color="inherit" className={classes.links} to="/sportsbar">
                Sports Bar
              </Link>
            </Box>
            <Box alignSelf="center" mx={2}>
              <Link component={RouterLink} color="inherit" className={classes.links} to="/whatson">
                What's On
              </Link>
            </Box>
            <Box alignSelf="center" mx={2}>
              <Link component={RouterLink} color="inherit" className={classes.links} to="/contact">
                Contact Us
              </Link>
            </Box>
          </Box>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" aria-label="open menu" onClick={() => setisOpen(true)} edge="start" className={classes.menuButton}>
            <StorageRoundedIcon fontSize="large" />
          </IconButton>
          <Drawer anchor="left" open={isOpen} onClose={() => setisOpen(false)}>
            <div className={classes.drawer}>
              <img src="/images/logo.png" alt="logo" className={classes.logo} />
              <hr />
              <Alert
                style={{ fontWeight: "bold", fontSize: "1rem", lineHeight: "1rem", verticalAlign: "center", backgroundColor: "brown", borderRadius: 0, color: "white", textAlign: "center", justifyContent: "center", alignContent: "center" }}
                icon={false}
              >
                <span className={classes.bookText}>We now take online bookings!</span>
                <Button
                  className={classes.book}
                  style={{ marginLeft: "10px", backgroundColor: "white", fontWeight: "bold", fontSize: ".8rem" }}
                  variant="contained"
                  href="https://www.sevenrooms.com/explore/gisbornetelegraphhotel/reservations/create/search"
                  target="_blank"
                >
                  Book Now
                </Button>
              </Alert>
              <List>
                <ListItem>
                  <Link component={RouterLink} color="inherit" onClick={() => setisOpen(false)} className={classes.links} to="/">
                    Home
                  </Link>
                </ListItem>
                <ListItem>
                  <Link component={RouterLink} color="inherit" onClick={() => setisOpen(false)} className={classes.links} to="/menu">
                    Menu
                  </Link>
                </ListItem>
                <ListItem>
                  <Link component={RouterLink} color="inherit" onClick={() => setisOpen(false)} className={classes.links} to="/steakoutmenu">
                    Steakout Menu
                  </Link>
                </ListItem>
                <ListItem>
                  <Link component={RouterLink} color="inherit" onClick={() => setisOpen(false)} className={classes.links} to="/functions">
                    Functions
                  </Link>
                </ListItem>
                <ListItem>
                  <Link component={RouterLink} color="inherit" onClick={() => setisOpen(false)} className={classes.links} to="/bereavement">
                    BEREAVEMENT/WAKE
                  </Link>
                </ListItem>
                <ListItem>
                  <Link component={RouterLink} color="inherit" onClick={() => setisOpen(false)} className={classes.links} to="/sportsbar">
                    Sports Bar
                  </Link>
                </ListItem>
                <ListItem>
                  <Link component={RouterLink} color="inherit" onClick={() => setisOpen(false)} className={classes.links} to="/whatson">
                    What's On
                  </Link>
                </ListItem>
                <ListItem>
                  <Link component={RouterLink} color="inherit" onClick={() => setisOpen(false)} className={classes.links} to="/contact">
                    Contact Us
                  </Link>
                </ListItem>
              </List>
            </div>
          </Drawer>
        </Hidden>
      </Box>
    </header>
  );
};

export default Header;
