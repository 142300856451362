import React, { useState, useEffect } from 'react'
import axios from 'axios'
import makeStyles from '@material-ui/core/styles/makeStyles';
import Fade from '@material-ui/core/Fade'
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import CardMedia from '@material-ui/core/CardMedia';
import Carousel from 'react-material-ui-carousel'

const useStyles = makeStyles((theme) => ({
    menuHeader: {
        textAlign: 'center'
    },
    contentCenter: {
        justifyContent: 'center'
    },
    menuTypes: {
        textTransform: 'uppercase',
        textAlign: 'center'
    },
    priceInfo:{
        justifyContent: 'flex-end',
        marginRight: theme.spacing(1)
    },
    stepContainer: {
        marginBottom: theme.spacing(4)
    },
    media: {
        height: '300px',
    },
    mediaFunctions: {
        maxHeight: '350px',
        maxWidth: '100%',
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[6]
    },
    carousel:{
        width:"100%"
    },
    [theme.breakpoints.up('md')]: {
        carousel:{
            width:"50%",
        }
    },
    [theme.breakpoints.up('xl')]: {
        carousel:{
            width:"40%",
        }
    },
    mediaOuter: {
        boxShadow: theme.shadows[24]
    },
    mediaImages: {
        textAlign: 'center',
    },
    darkCard: {
        backgroundColor: theme.palette.functionCardBackground,
        color: theme.palette.functionCardText,
    },
    cardHeader: {
      backgroundColor: theme.palette.functionCardBackground,
    },
    list: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
        textTransform: 'uppercase'
    }
}))

const WhatsOn = props => {
    const classes = useStyles()
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(true)

    async function fetchData() {
        let response = await axios.get("https://tele.retailbs.com.au/api/get_events.php").then((res)=>{
            if(!res.data.error){
                return res.data.data
            }
            return []
        }).catch(err => {
            return []
        })
        setItems(response);
        setLoading(false)
      }

    useEffect(() => {
        fetchData();
    }, [])

    let carousel = 
    <Box className={classes.menuHeader}>
        <Fade in>
                        <CircularProgress />
                    </Fade>
                    </Box>
    if(!loading) {
        if(items){
            if(items.length > 0){
                carousel = <Carousel navButtonsAlwaysVisible animation="slide" interval="6000">
                    {
                        items.map( (item, i) => <img key={i} className={classes.carousel} src={item.filenames} alt={item.filenames} /> )
                    }
                </Carousel>
            } else {
                carousel = <Typography variant="body2" color="primary" component="div">We currently don't have any upcoming events on, but be sure to check back often.</Typography>
            }
        } else {
            carousel = <Typography variant="body2" color="primary" component="div">We currently don't have any upcoming events on, but be sure to check back often.</Typography>
        }
    }

    return (
        <Fade timeout={2000} in>
            <Container maxWidth="xl">
                <Box pt={8} pb={10}>
                    <Grid container spacing={6} alignItems="center" className={classes.stepContainer}>
                        <Grid item xs={12} md={3}>
                            <Box height="100%">
                                <Typography variant="h3" color="primary" component="h3">WHAT'S ON</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Slide direction="left" timeout={1000} in mountOnEnter unmountOnExit>
                                <Card className={classes.mediaOuter}>
                                    <CardMedia className={classes.media} image="/images/whats-on-1.jpg" />
                                </Card>
                            </Slide>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" justify="center">
                        <Grid item xs={12}>
                            {carousel}
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Fade>
    )
}

export default WhatsOn