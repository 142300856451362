import React, { useState, useEffect } from "react";
import axios from "axios";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import CardMedia from "@material-ui/core/CardMedia";

function sentenceCase(str) {
  return str
    .replace(/[a-z]/i, function (letter) {
      return letter.toUpperCase();
    })
    .trim();
}

function decodeHTML(html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

const useStyles = makeStyles((theme) => ({
  menuHeader: {
    textAlign: "center",
  },
  contentCenter: {
    justifyContent: "center",
  },
  menuTypes: {
    textTransform: "uppercase",
    textAlign: "center",
    fontFamily: "Permanent Marker, cursive !important",
    fontSize: "3rem",
  },
  stepContainer: {
    marginBottom: theme.spacing(4),
  },
  media: {
    height: "300px",
  },
  mediaOuter: {
    boxShadow: theme.shadows[24],
  },
  card: {
    borderRadius: 0,
    boxShadow: "none",
  },
  cardAction: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    minWidth: "150px",
  },
}));

const SteakoutMenu = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [wineloading, setWineLoading] = useState(true);
  const [winedata, setWineData] = useState([]);

  async function fetchData() {
    let response = await axios
      .get("https://tele.retailbs.com.au/api/get_steakout_menu.php")
      .then((res) => {
        if (!res.data.error) {
          return res.data.data;
        }
        return [];
      })
      .catch((err) => {
        return [];
      });
    setData(response);
    setLoading(false);
  }

  async function fetchWineData() {
    let response = await axios
      .get("https://tele.retailbs.com.au/api/get_steakout_wine_menu.php")
      .then((res) => {
        if (!res.data.error) {
          return res.data.data;
        }
        return [];
      })
      .catch((err) => {
        return [];
      });
    setWineData(response);
    setWineLoading(false);
  }

  useEffect(() => {
    fetchData();
    fetchWineData();
  }, []);

  let menu = (
    <Grid item className={classes.menuHeader} xs={12}>
      <Fade in>
        <CircularProgress />
      </Fade>
    </Grid>
  );

  let wineMenu;

  if (!loading) {
    if (data.length > 0) {
      menu = data.map((val, idx) => {
        let menuItems;
        if (val.items.length > 0) {
          menuItems = val.items.map((mval, midx) => {
            if (mval.meal_name) {
              return (
                <Grid key={midx} item xs={12} md={6}>
                  <Card className={classes.card}>
                    <CardHeader
                      title={mval.meal_name}
                      titleTypographyProps={{ className: "steakout" }}
                      subheader={mval.meal_desc && sentenceCase(mval.meal_desc.toLowerCase())}
                      subheaderTypographyProps={{ className: "steakout" }}
                      action={
                        <Typography variant="h6" component="span">
                          <strong>{mval.price}</strong>
                        </Typography>
                      }
                      classes={{ action: classes.cardAction }}
                    />
                  </Card>
                </Grid>
              );
            } else {
              if (mval.meal_desc) {
                return (
                  <Grid key={midx} item xs={12}>
                    <Card className={classes.card}>
                      <CardHeader subheader={mval.meal_desc && sentenceCase(mval.meal_desc.toLowerCase())} subheaderTypographyProps={{ className: "steakout" }} />
                    </Card>
                  </Grid>
                );
              } else {
                return false;
              }
            }
          });
        }
        return (
          <Fade key={idx} in>
            <Grid item xs={12}>
              <h2 className={classes.menuTypes}>{val.type_name}</h2>
              {val.menu_desc && (
                <Typography className={classes.menuTypes} variant="subtitle1" component="h5" gutterBottom={true}>
                  {val.menu_desc}
                </Typography>
              )}
              <hr />
              {idx === 2 && (
                <Box mx="auto" style={{ textAlign: "center" }}>
                  <img src="/images/steak-cuts.png" alt="steak cuts" style={{ maxWidth: "100%" }} />
                </Box>
              )}
              <Grid container spacing={1}>
                {menuItems}
              </Grid>
            </Grid>
          </Fade>
        );
      });
    } else {
      menu = (
        <Grid item xs={12}>
          <Fade in>
            <Alert className={classes.contentCenter} style={{ fontWeight: "bold", fontSize: "1.1rem" }} icon={false} variant="filled" severity="warning">
              New menu under construction, please check back soon.
            </Alert>
          </Fade>
        </Grid>
      );
    }
  }
  if (!wineloading) {
    if (winedata.length > 0) {
      wineMenu = (
        <>
          <Grid container spacing={6} alignItems="center" className={classes.stepContainer}>
            <Grid item xs={12} md={3}>
              <Box height="100%">
                <Typography variant="h3" color="primary" style={{ textAlign: "center" }} component="h3">
                  WINE LIST
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={9}>
              <Slide direction="left" timeout={1000} in mountOnEnter unmountOnExit>
                <Card className={classes.mediaOuter}>
                  <CardMedia className={classes.media} image="/images/wine-list-1.jpg" />
                </Card>
              </Slide>
            </Grid>
          </Grid>
          {winedata.map((val, idx) => {
            let wineMenuItems;
            if (val.items.length > 0) {
              wineMenuItems = val.items.map((mval, midx) => {
                if (mval.menu_name) {
                  return (
                    <Grid key={midx} item xs={12} md={6}>
                      <Card className={classes.card}>
                        <Grid container>
                          <Grid item xs={10} md={8}>
                            <Typography variant="h5" component="div" className="steakout">
                              {decodeHTML(mval.menu_name)}
                            </Typography>
                          </Grid>
                          <Grid item xs={1} md={2}>
                            {mval.price_glass && (
                              <Typography variant="body1" component="div" style={{ textAlign: "center" }}>
                                <small>Glass</small>
                                <br />
                                <strong>{mval.price_glass}</strong>
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={1} md={2}>
                            <Typography variant="body1" component="div" style={{ textAlign: "center" }}>
                              <small>Bottle</small>
                              <br />
                              <strong>{mval.price_bottle}</strong>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  );
                }
                return false;
              });
            }
            return (
              <Fade key={idx} in>
                <Grid item xs={12}>
                  <h2 className={classes.menuTypes}>{val.type_name}</h2>
                  <hr />
                  <Grid container spacing={1}>
                    {wineMenuItems}
                  </Grid>
                </Grid>
              </Fade>
            );
          })}
        </>
      );
    } else {
      wineMenu = (
        <Grid item xs={12}>
          <Fade in>
            <Alert className={classes.contentCenter} style={{ fontWeight: "bold", fontSize: "1.1rem" }} icon={false} variant="filled" severity="warning">
              New wine menu under construction, please check back soon.
            </Alert>
          </Fade>
        </Grid>
      );
    }
  }
  return (
    <Fade timeout={2000} in>
      <Container maxWidth="xl">
        <Box pt={8} pb={10}>
          <Grid container spacing={6} alignItems="center" className={classes.stepContainer}>
            <Grid item xs={12} md={3}>
              <Box height="100%">
                <Typography variant="h3" color="primary" component="h3">
                  STEAKOUT MENU
                </Typography>
                <Typography variant="subtitle1" component="h2" gutterBottom={true}>
                  STEAKOUT @ THE TELEGRAPH HOTEL OFFERS FINE DINING IN OUR NEWLY RENOVATED RESTURANT.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={9}>
              <Slide direction="left" timeout={1000} in mountOnEnter unmountOnExit>
                <Card className={classes.mediaOuter}>
                  <CardMedia className={classes.media} image="/images/menu-2.jpg" />
                </Card>
              </Slide>
            </Grid>
          </Grid>
          <Grid style={{ backgroundColor: "#fff" }} container spacing={6}>
            <Box width={"100%"} p={3}>
              {menu}
            </Box>
          </Grid>
          <Grid style={{ backgroundColor: "#fff" }} container spacing={6}>
            <Box p={3}>{wineMenu}</Box>
          </Grid>
        </Box>
      </Container>
    </Fade>
  );
};

export default SteakoutMenu;
